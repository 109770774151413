<template>
  <section v-if="gameCount"><!-- loaded && gameCount -->
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
      </div>
    </transition>
    <!--button class="dropdown" :value="categoryName" @click="aselect=!aselect">{{ categoryName }}</button-->
    <div class="aselect">
       <ul :class="{'active': currentTab == ''}">
         <li :class="{'on': currentTab == 'tab-0', 'off': currentTab != 'tab-0'}" data-tab="tab-0"
          @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-0')">
           <a class="gamem">
             <i class="ihome"></i>
             <span>홈</span>
           </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.etc'), 'tab-8')"
            :class="{'on': currentTab == 'tab-8', 'off': currentTab != 'tab-8'}"
            data-tab="tab-8">
            <a class="gamem">
               <i class="ihcasino"></i>
               <span>인기게임</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.sport'), 'tab-5')"
            v-if="gameCount['sports']"
            :class="{'on': currentTab == 'tab-5', 'off': currentTab != 'tab-5'}"
            data-tab="tab-5">
            <a class="gamem">
               <i class="isports"></i>
               <span>스포츠</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-2')"
            v-if="gameCount['casino']"
            :class="{'on': currentTab == 'tab-2', 'off': currentTab != 'tab-2'}"
            data-tab="tab-2">
            <a class="gamem">
               <i class="icasino"></i>
               <span>{{$t('front.gnb.livecasino')}}</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gnb.hotelcasino'), 'tab-3')"
            v-if="gameCount['hc-casino']"
            :class="{'on': currentTab == 'tab-3', 'off': currentTab != 'tab-3'}"
            data-tab="tab-3">
            <a class="gamem">
               <i class="ihcasino"></i>
               <span>호텔카지노</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.slot'), 'tab-4')"
            v-if="gameCount['slot']"
            :class="{'on': currentTab == 'tab-4', 'off': currentTab != 'tab-4'}"
            data-tab="tab-4">
            <a class="gamem">
               <i class="islot"></i>
               <span>{{$t('front.gnb.slot')}}</span>
            </a>
         </li>
         <li
            @click="selectCategory($t('front.gameCategory.minigame'), 'tab-6')"
            v-if="gameCount['mini-game']"
            :class="{'on': currentTab == 'tab-6', 'off': currentTab != 'tab-6'}"
            data-tab="tab-6">
            <a class="gamem">
               <i class="imini"></i>
               <span>{{$t('front.gnb.minigame')}}</span>
            </a>
         </li>
         <!--li @click="nalert">
            <a class="gamem">
               <i class="iball"></i>
               <span>파워볼</span>
            </a>
         </li-->
       </ul>
    </div>
   <div v-if="currentTab == 'tab-0'">
      <div class="noticeWrap">
        <div class="notice"><em><img src="@/assets/img/left/icon_left_notice_wh.svg">{{$t('front.common.notices')}}</em><span>{{$t('front.header.msg')}}</span></div>
      </div>
      <transition name="fade">
        <ui-swiper />
      </transition>
      <h3 class="gamename">
        인기게임
        <a @click="selectCategory($t('front.gameCategory.etc'), 'tab-8')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
     </h3>
     <ul class="gamewrap2">
      <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_splus_off.png" />
          <p class="name">
           <span>SPLUS 스포츠</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo"></em>
          <span class="lbest"></span>
          <a @click="onCasinoSelectGame('sport', 'splus')"></a>
       </li>
      <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_1_off.png" />
          <p class="name">
           <span>에볼루션</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <!-- <em class="glogo"></em> -->
          <span class="lbest"></span>
          <a @click="onCasinoSelectGame('casino', 1)"></a>
       </li>
       <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_200_off.png" />
          <p class="name">
           <span>프라그마틱 슬롯</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo prs"></em>
          <span class="lhot"></span>
          <a @click="slotOpen('isSlot', 'slot', '프라그마틱플레이 슬롯', 200)"></a>
       </li>
       <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_208_off.png" />
          <p class="name">
           <span>PG소프트</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo pgs"></em>
          <span class="lhot"></span>
          <a @click="slotOpen('isSlot', 'slot', 'PG소프트', 208)"></a>
       </li>
       <!-- <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_33_off.png" />
          <p class="name">
           <span>하이로우</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo pgs"></em>
          <span class="lhot"></span>
          <a @click="onCasinoSelectGame('minigame', 33)"></a>
       </li> -->
       <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_10_off.png" />
          <p class="name">
           <span>프라그마틱 카지노</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <!-- <em class="glogo pgs"></em> -->
          <span class="lhot"></span>
          <a @click="onCasinoSelectGame('casino', 10)"></a>
       </li>
       <!-- <li>
          <img src="@/assets/img/gamecardNew/game_main_pc_niu_off.png" />
          <p class="name">
           <span>니우니우</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo pgs"></em>
          <span class="lhot"></span>
          <a @click="onCasinoSelectGame('minigame', 'niu')"></a>
       </li> -->
    </ul>
    <h3 class="gamename">
      {{ $t('front.gnb.sport') }}
      <a @click="selectCategory($t('front.gameCategory.sport'), 'tab-5')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
    </h3>
    <ul class="gamewrap2">
     <template v-for="game in gameList" v-bind:key="game.code">
     <li v-if="game.groupCode === 'sport'"
          :class="game.type">
         <img :src="getBackgroundImage(game)" />
         <p class="name">
          <span>{{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}</span>
          <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
         </p>
         <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
         <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
     </li>
     </template>
   </ul>
      <h3 class="gamename">
         {{ $t('front.gnb.livecasino') }}
         <a @click="selectCategory($t('front.gameCategory.livecasino'), 'tab-2')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
      </h3>
      <ul class="gamewrap2">
       <template v-for="game in gameList" v-bind:key="game.code">
        <li v-if="game.groupCode === 'casino'"
            :class="game.type">
           <img :src="getBackgroundImage(game)" />
           <p class="name">
            <span>{{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}</span>
            <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
           </p>
           <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
           <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
        </li>
       </template>
     </ul>
     <!-- <h3 class="gamename">
        {{ $t('front.gnb.hotelcasino') }}
        <a @click="selectCategory($t('front.gnb.hotelcasino'), 'tab-3')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
     </h3>
     <ul class="gamewrap2">
      <template v-for="game in gameList" v-bind:key="game.code">
       <li v-if="game.groupCodeName.indexOf('HC-') > -1"
           :class="game.type">
          <img :src="getBackgroundImage(game)" />
          <p class="name">
           <span>{{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
          <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
       </li>
      </template>
    </ul> -->
    <h3 class="gamename">
      {{ $t('front.gnb.slot') }}
      <a @click="selectCategory($t('front.gameCategory.slot'), 'tab-4')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
    </h3>
     <ul class="gamewrap2">
      <template v-for="game in gameList" v-bind:key="game.code">
       <li v-if="game.groupCode === 'slot'"
           :class="game.type">
          <img :src="getBackgroundImage(game)" />
          <p class="name">
           <span>{{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}</span>
           <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
          <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
       </li>
      </template>
    </ul>
    <h3 class="gamename">
      라이브 홀덤
      <a @click="selectCategory($t('front.gameCategory.minigame'), 'tab-6')">모두보기<img src="@/assets_mobile/img/icon_rightarrow.svg" /></a>
    </h3>
    <ul class="gamewrap2 holdem">
      <!-- <template v-for="game in gameList" v-bind:key="game.code">
      <li v-if="game.groupCode === 'minigame'"
            :class="game.type">
          <img :src="getBackgroundImage(game)" />
          <p class="name">
            <span>{{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}</span>
            <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
          </p>
          <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
          <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
      </li>
      </template> -->
      <li>
        <a href="http://nvn-0879.com" target="_blank" class="link">
          <img src="../assets/img/holdem-n.png">
        </a>
      </li>
    </ul>
    <section class="bwrap">
      <h2 class="mainnames">
         {{ $t('front.common.notice') }}
         <a class="more" @click="goPageByName('noticeList')">+ {{ $t('front.board.more') }}</a>
      </h2>
      <div class="servicecenter">
         <ul @click="goPageByName('noticeList')">
            <template v-for="item in noticeList" :key="item">
               <li>
                  <span><i>[일반안내]</i>{{item.title}}</span>
                  <em>{{item.regDate}}</em>
               </li>
            </template>
            <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
               <li>
                  <span>&nbsp;</span>
               </li>
            </template>
         </ul>
      </div>
      <div class="callwrap">
         <ul class="sns" v-if="siteOption">
            <li>
               <a @click="siteOption['sns2Link']">
                  <img src="@/assets/img/icon_kakao.svg" />
                  <span>{{siteOption['sns2']}}<em>{{siteOption['sns2Id']}}</em></span>
               </a>
            </li>
            <li>
               <a @click="siteOption['sns1Link']">
                  <img src="@/assets/img/telegram.svg" />
                  <span>{{siteOption['sns1']}}<em>{{siteOption['sns1Id']}}</em></span>
               </a>
            </li>
            <li v-if="siteOption['sns3']">
               <a @click="siteOption['sns3Link']">
                  <img src="@/assets/img/zalo.svg" />
                  <span>{{siteOption['sns3']}}<em>{{siteOption['sns3Id']}}</em></span>
               </a>
            </li>
         </ul>
         <div class="txt">
            <h4>24시간 고객센터</h4>
            <span>나인의 모든 상담원은 24시간<br />고객님을 위해 대기하고 있습니다.</span>
         </div>
      </div>
   </section>
   <div class="banner3">
      <h4>공식 파트너사</h4>
      <transition name="fade">
       <ui-swipera />
      </transition>
   </div>
   <!--section>
      <h2 class="mainnames">
         <img src="@/assets_mobile/img/icon_m_inout.svg" />
         {{ $t('front.main.livecharge') }}
      </h2>
      <div class="inoutwrap">
         <div class="btn">
            <a @click="onChangeType('in')" :class="{'active': type === 'in'}">{{ $t('front.main.livecharge') }}</a>
            <a @click="onChangeType('out')" :class="{'active': type === 'out'}">{{ $t('front.main.liveExchange') }}</a>
         </div>
         <div v-if="type == 'in'" class="inout">
            <ul>
              <template v-for="item in cashInList" :key="item.memId">
                <li>
                  <span><img src="@/assets/img/icon_inin.svg" />{{item.memId}}</span>
                  <span>{{thousand(Number(item.cashAmt))}} 원</span>
                  <span>{{item.regDate}}</span>
                </li>
              </template>
              <template v-for="item in listMaxCount - cashInList.length" :key="item">
              </template>
            </ul>
         </div>
         <div v-if="type == 'out'" class="inout">
            <ul>
              <template v-for="item in cashOutList" :key="item.memId">
                <li>
                  <span><img src="@/assets/img/icon_outout.svg" />{{item.memId}}</span>
                  <span>{{thousand(Number(item.cashAmt*-1))}} 원</span>
                  <span>{{item.regDate}}</span>
                </li>
              </template>
              <template v-for="item in listMaxCount - cashOutList.length" :key="item">
              </template>
            </ul>
         </div>
      </div>
   </section-->
   </div>

   <div v-if="currentTab == 'tab-8'">
      <ul class="gamewrap">
         <li class="tab-2">
            <img src="@/assets/img/gamecardNew/game_main_pc_splus_off.png" />
            <p class="name">
               <span>
                  SPLUS 스포츠
                  <em class="casino">{{ $t('front.gnb.sport') }}</em>
               </span>
               <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
            </p>
            <em class="glogo"></em>
            <span class="lbest"></span>
            <a @click="onCasinoSelectGame('sport', 'splus')"></a>
         </li>
         <li class="tab-2">
            <img src="@/assets/img/gamecardNew/game_main_pc_1_off.png" />
            <p class="name">
               <span>
                  에볼루션
                  <em class="casino">{{ $t('front.gnb.casino') }}</em>
               </span>
               <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
            </p>
            <em class="glogo"></em>
            <span class="lbest"></span>
            <a @click="onCasinoSelectGame('casino', 1)"></a>
         </li>
         <li class="tab-2">
            <img src="@/assets/img/gamecardNew/game_main_pc_200_off.png" />
            <p class="name">
               <span>
                  프라그마틱 슬롯
                  <em class="casino">{{ $t('front.gnb.slot') }}</em>
               </span>
               <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
            </p>
            <em class="glogo prs"></em>
            <span class="lhot"></span>
            <a @click="slotOpen('isSlot', 'slot', '프라그마틱플레이 슬롯', 200)"></a>
         </li>
         <li class="tab-2">
            <img src="@/assets/img/gamecardNew/game_main_pc_208_off.png" />
            <p class="name">
               <span>
                  PG소프트
                  <em class="casino">{{ $t('front.gnb.slot') }}</em>
               </span>
               <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
            </p>
            <em class="glogo pgs"></em>
            <span class="lhot"></span>
            <a @click="slotOpen('isSlot', 'slot', 'PG소프트', 208)"></a>
         </li>
         <li class="tab-2">
           <img src="@/assets/img/gamecardNew/game_main_pc_33_off.png" />
           <p class="name">
            <span>
               하이로우
               <em class="casino">{{ $t('front.gnb.minigame') }}</em>
            </span>
            <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
           </p>
           <span class="lhot"></span>
           <a @click="onCasinoSelectGame('minigame', 33)"></a>
        </li>
        <li class="tab-2">
           <img src="@/assets/img/gamecardNew/game_main_pc_10_off.png" />
           <p class="name">
            <span>
               프라그마틱 카지노
               <em class="casino">{{ $t('front.gnb.casino') }}</em>
            </span>
            <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
           </p>
           <span class="lhot"></span>
           <a @click="onCasinoSelectGame('casino', 10)"></a>
        </li>
        <li class="tab-2">
           <img src="@/assets/img/gamecardNew/game_main_pc_niu_off.png" />
           <p class="name">
            <span>
               니우니우
               <em class="casino">{{ $t('front.gnb.minigame') }}</em>
            </span>
            <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
           </p>
           <span class="lhot"></span>
           <a @click="onCasinoSelectGame('minigame', 'niu')"></a>
        </li>
      </ul>
   </div>

   <div class="tab-content" v-bind:key="currentTab">
     <ul class="gamewrap">
       <template v-for="game in gameList" v-bind:key="game.code">
         <li v-if="currentTab == 'tab-1' || (currentTab != 'tab-1' && currentTab==game.type)"
             :class="game.type">
           <img :src="getBackgroundImage(game)" />
           <p class="name">
             <span>
                {{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}
                <em :class="game.groupCode">
                  <template v-if="game.groupCode === 'casino'">
                    <template v-if="game.groupCodeName.indexOf('HC-') === -1">
                      {{ $t('front.gnb.livecasino') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelcasino') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'slot'">
                    <template v-if="game.groupCodeName.indexOf('H-') === -1">
                      {{ $t('front.gnb.slot') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelslot') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'minigame'">
                    {{ $t('front.gnb.minigame') }}
                  </template>
                  <template v-if="game.groupCode === 'sport'">
                    {{ $t('front.gnb.sport') }}
                  </template>
                </em>
             </span>
             <a @click="gamebox=!gamebox" class="gameboxbtn"><img src="@/assets/img/icon_qusetion.svg" /></a>
           </p>
           <em class="glogo" :style="getBackgroundLogoImage(game)"></em>
           <template v-if="game.groupCode == 'slot'">
             <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
           </template>

           <template v-else-if="game.groupCode == 'new'">
             <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
           </template>
           <template v-else>
             <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
           </template>
         </li>
       </template>
     </ul>
   </div>

  </section>
  <!--div v-if="gamebox" class="gamebox">
     <a @click="gamebox=!gamebox" class="close"><img src="@/assets/img/icon_cancelB.svg" /></a>
     <h4>에볼루션</h4>
     <pre>베팅금 : 최소 1,000,000 - 최대 100,000,000

1:1 VIP 전용 테이블, 무제한 슈 체인지
베팅시간의 시간적 제약이 없어
자신의 페이스에 맞게 플레이 가능!
다른 딜러를 원하실 경우 대화창에 딜러 교체 요청!</pre>
     <a @click="gamebox=!gamebox" class="check">확인</a>
  </div-->

  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
      <img :src="item.bannerImg" v-if="item.bannerImg" />
        <div class="txtwrap">
          <h3>{{item.bannerTitle}}</h3>
          <ul class="popupTxt" v-if="item.bannerContent">
            <li v-html="item.bannerContent">
            </li>
          </ul>
        </div>
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getSlotGame } from '@/api/game'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import UiSwipera from '@/components/ui/UiSwipera.vue'

export default {
  name: 'MIndex',
  components: {
    UiSwiper,
    UiSwipera
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount',
      'siteOption'
    ])
  },
  data () {
    return {
      type: 'in',
      categoryName: this.$t('front.gameCategory.livecasino'),
      loaded: false,
      currentTab: 'tab-0',
      gameList: [],
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      popupList: {},
      listMaxCount: 3,
      noticeListMaxCount: 5,
      gamebox: false
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    nalert () {
      alert('준비중입니다.')
    },
    selectCategory (text, tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab
      } else {
        // this.currentTab = ''
      }
      this.categoryName = text
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/gamecardNew/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = {
              ...item,
              bannerContent: item.bannerContent.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp;')
            }
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    onChangeType (type) {
      this.type = type
    }
  },
  created () {
    this.loadMain()
    this.loadNotice()
    this.loadCashStatus()
    setTimeout(() => {
      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    }, 500)
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>
<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.swiper-button-next {background-image: url('~@/assets/img/slideright.png') !important;background-size: 11px 20px !important;}
.swiper-button-prev {background-image: url('~@/assets/img/slideleft.png') !important;background-size: 11px 20px !important;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
